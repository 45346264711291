
/* .header, .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
} */

/* .section {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  align-items: center; 
}*/

/* .section-content {
  max-width: 1200px;
  margin: 0 auto;
} */
.header{
  background: rgb(255 255 255 / 0%);
  /* position: fixed; */
  /* width: 100%; */
}
.navbar {
  background-color: rgba(255, 255, 255, 0);
}

#hero {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.hero-body div {
  width: 50%;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.navbar-brand {
  max-width: 120px;
}

.navbar-brand img {
  width: 100%;
}

.navbar button {
  margin-left: 1em;
}

.text-left {
  text-align: left;
}

.carousel-inner {
  max-width: 1000px;
  margin: 0 auto;
}

/* .carousel-item {
  min-height: 75vh;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

body {
  margin-bottom: -20px;
}

.modal .form-group {
  text-align: left;
  margin-bottom: 1em;
}

.modal label {
  margin-bottom: 5px;
}

.modal .close {
  border-radius: 20px;
  padding: 4px;
  width: 35px;
  font-weight: bold;
}

.modal button {
  margin: 0 10px;
}

.pac-container {
  z-index: 10000; /* Adjust as needed */
}

.footer {
  margin-bottom: -25px;
}

footer li {
  font-size: 25px;
}

@media (max-width: 500px) {
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  footer .align-items-center {
    flex-direction: column;
  }
}